import { useMutation } from '@apollo/client';
import { Button, Col, Row } from 'antd';
import { debounce, includes } from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { PremiumIcon } from '../../assets/svg';
import {
  GA_EVENT_NAMES,
  GA_EVENT_PARAMETERS,
  LAYOUT_OPTIONS,
} from '../../common/constants';
import { GaEvent } from '../../common/utils';
import useFeatureAccess from '../FeatureAccess/hooks/useFeatureAccess';
import { UPDATE_LAYOUT } from '../Widget/graphql/Mutations';
import LayoutController from './LayoutController';
import ToggleLayout from './components/ToggleLayout';

function WidgetLayout() {
  const { state, toggleFeatureAccessModal } = useContext(AppContext);
  const {
    previewStyle,
    previewLayoutObject: { col, row, gap, width },
    headerController,
    showHeaders,
    columnMode,
    sliderSettings: contextSliderSettings,
    feedTitle,
    openFeatureAccessModal,
  } = state;

  const isFirstRender = useRef(true);
  const { id } = useParams();
  const [inColRow, setInColRow] = useState(false);
  const [inHeaders, setInHeaders] = useState(false);
  const [inSliderSetting, setInSliderSetting] = useState(false);
  const [inFeedTitle, setInFeedTitle] = useState(false);
  const { widgetAccessConfig: hasAccessTo } = useFeatureAccess({
    contextToUse: AppContext,
  });

  const isMainLayout =
    !inHeaders && !inColRow && !inSliderSetting && !inFeedTitle;

  const handleColRow = () => {
    GaEvent(GA_EVENT_NAMES.COL_ROW_SETTINGS, {
      [GA_EVENT_PARAMETERS.WIDGET_ID]: id,
      [GA_EVENT_PARAMETERS.LAYOUT_TYPE]: previewStyle,
    });
    setInColRow(true);
  };

  const handleHeaders = () => {
    GaEvent(GA_EVENT_NAMES.HEADER_SETTINGS, {
      [GA_EVENT_PARAMETERS.WIDGET_ID]: id,
      [GA_EVENT_PARAMETERS.LAYOUT_TYPE]: previewStyle,
    });
    if (!hasAccessTo?.allowAccountInfoWithFollowButton) {
      toggleFeatureAccessModal({
        ...openFeatureAccessModal,
        status: true,
        info: 'Connect to your personal instagram account.',
      });
      return;
    }
    setInHeaders(true);
  };

  const handleSliderSetting = () => {
    GaEvent(GA_EVENT_NAMES.SLIDER_SETTINGS, {
      [GA_EVENT_PARAMETERS.WIDGET_ID]: id,
      [GA_EVENT_PARAMETERS.LAYOUT_TYPE]: previewStyle,
    });
    setInSliderSetting(true);
  };

  const handleFeedTitle = () => {
    GaEvent(GA_EVENT_NAMES.FEED_TITLE_SETTINGS, {
      [GA_EVENT_PARAMETERS.WIDGET_ID]: id,
      [GA_EVENT_PARAMETERS.LAYOUT_TYPE]: previewStyle,
    });
    setInFeedTitle(true);
  };

  const isSlider = previewStyle === LAYOUT_OPTIONS?.SLIDER?.value;

  const [updateWidgetLayout] = useMutation(UPDATE_LAYOUT, {
    onError() {},
  });

  const debounceUpdateLayout = useMemo(
    () => debounce(updateWidgetLayout, 500),
    [updateWidgetLayout],
  );

  const updateLayout = useCallback(() => {
    const controlHeader = (section) => includes(headerController, section);

    const columnsAndRows = {
      config: {
        columns: col || 4,
        gap: gap || 0,
        rows: row || 1,
        width: width || 'auto',
      },
      mode: columnMode,
    };

    const headers = {
      config: {
        followButton: controlHeader('followButton'),
        followersCount: controlHeader('followersCount'),
        followingCount: controlHeader('followingCount'),
        fullName: controlHeader('fullName'),
        postsCount: controlHeader('postsCount'),
        profilePicture: controlHeader('profilePicture'),
        userName: controlHeader('userName'),
        verifiedBadge: controlHeader('verifiedBadge'),
      },
      showHeaders,
    };

    const sliderSettings = {
      ...contextSliderSettings,
    };

    const layoutData = {
      columnsAndRows,
      feedTitle,
      headers,
      sliderSettings,
      type: previewStyle,
      widgetId: id,
    };

    try {
      debounceUpdateLayout({
        variables: {
          data: layoutData,
        },
      });
    } catch (error) {
      return false;
    }
  }, [
    previewStyle,
    col,
    row,
    gap,
    width,
    headerController,
    showHeaders,
    columnMode,
    contextSliderSettings,
    feedTitle,
    debounceUpdateLayout,
  ]);

  useEffect(() => {
    if (isFirstRender?.current) {
      isFirstRender.current = false;
      return;
    }
    updateLayout();
  }, [updateLayout]);

  return (
    <div className="text-color">
      {isMainLayout ? (
        <>
          <h2>Layout</h2>
          <Row gutter={[0, 20]}>
            <Col span={24}>
              <div className="action-preview-screen">
                <ToggleLayout />
              </div>
            </Col>
            <Col span={24}>
              <Button
                className="mt-8 mb-8 all-btn full-width"
                size="large"
                onClick={handleColRow}
              >
                Columns and Rows
              </Button>
              <Button
                className="mt-8 mb-8 all-btn full-width"
                size="large"
                onClick={handleHeaders}
              >
                <div span={22} className="d-flex justify-center col-gap-8">
                  {!hasAccessTo?.allowAccountInfoWithFollowButton && (
                    <PremiumIcon className="h-20 w-20" />
                  )}
                  <span>Headers</span>
                </div>
              </Button>
              {isSlider && (
                <Button
                  className="mt-8 mb-8 all-btn full-width"
                  size="large"
                  onClick={handleSliderSetting}
                >
                  Slider Settings
                </Button>
              )}

              <Button
                className="mt-8 mb-8 all-btn full-width"
                size="large"
                onClick={handleFeedTitle}
              >
                Feed Title
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <LayoutController
            inColRow={inColRow}
            setInColRow={setInColRow}
            inHeaders={inHeaders}
            setInHeaders={setInHeaders}
            inSliderSetting={inSliderSetting}
            setInSliderSetting={setInSliderSetting}
            inFeedTitle={inFeedTitle}
            setInFeedTitle={setInFeedTitle}
          />
        </>
      )}
    </div>
  );
}

export default WidgetLayout;
