import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { initializePaddle } from '@paddle/paddle-js';
import { map } from 'lodash';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../../AppContext';
import UserProfile from '../../../app/components/header/UserProfile';
import {
  GA_EVENT_NAMES,
  PAYMENT_CHECKOUT,
  PLANS,
} from '../../../common/constants';
import { GaEvent } from '../../../common/utils';
import CommonLogo from '../../../components/CommonLogo';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import {
  REGISTER_CUSTOMER,
  SUBSCRIBE_TO_PLAN_PADDLE,
} from '../../FeatureAccess/Graphql/Mutations';
import { PRICING_PLAN_LIST } from '../../FeatureAccess/Graphql/Queries';
import USER_PROFILE from '../../auth/graphql/Queries';
import PricingTable from '../components/PricingTable';
import CANCEL_PADDLE_SUBSCRIPTION from './graphql/Mutations';

const parseObjectValues = (inputObject, featureFor) => {
  const parsedObject = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const key in inputObject) {
    if (Object?.prototype?.hasOwnProperty?.call(inputObject, key)) {
      try {
        const parsedValue = JSON?.parse(inputObject?.[key]);
        parsedObject[key] = {
          value: parsedValue?.value,
          key,
          name: parsedValue?.name,
          order: parsedValue?.order || 0,
          ...featureFor,
        };
      } catch (error) {
        parsedObject[key] = inputObject?.[key];
      }
    }
  }
  const { APP, ...restData } = parsedObject;
  const sortedKeys = Object?.keys(restData)?.sort();

  const sortedObject = sortedKeys?.reduce((acc, key) => {
    acc[key] = restData?.[key];
    return acc;
  }, {});
  return Object.fromEntries(
    Object.entries(sortedObject).sort((a, b) => a[1]?.order - b[1]?.order),
  );
};

const parseFeaturesInArray = (dataArray) =>
  dataArray?.map((item) => ({
    features: {
      ...parseObjectValues(item.features, { plan: item?.key }),
    },
  }));

function PricingList() {
  const [waitForSuccess, setWaitForSuccess] = useState(false);
  const { setCurrentUser } = useContext(AppContext);
  const [headers, setHeaders] = useState([]);
  const [data, setData] = useState([]);
  const [getUser] = useLazyQuery(USER_PROFILE, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setCurrentUser(res?.userProfile);
    },
    onError() {},
  });

  const { loading } = useQuery(PRICING_PLAN_LIST, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      if (!res?.subscriptionPlans) return;

      const plansHeaders = map(res?.subscriptionPlans, (plan) => ({
        plan: PLANS?.[plan?.key],
        billingAmount: plan?.billingAmount,
        billingType: plan?.billingType,
        id: plan?.id,
        currency: plan?.currency,
      }))?.sort((a, b) => a?.billingAmount - b?.billingAmount);
      setData(
        parseFeaturesInArray(
          res?.subscriptionPlans?.sort(
            (a, b) => a?.billingAmount - b?.billingAmount,
          ),
        ),
      );
      setHeaders(plansHeaders);
    },
    onError() {},
  });

  const [registerCustomer] = useMutation(REGISTER_CUSTOMER, {
    onCompleted() {},
    onError() {},
  });

  const [cancelSubscription] = useMutation(CANCEL_PADDLE_SUBSCRIPTION, {
    onCompleted() {},
    onError() {},
  });

  const [subscribeToPlan] = useMutation(SUBSCRIBE_TO_PLAN_PADDLE, {
    async onCompleted(res) {
      if (res) {
        initializePaddle({
          environment: process.env.REACT_APP_PADDLE_ENVIRONMENT,
          token: process.env.REACT_APP_PADDLE_CLIENT_TOKEN,

          eventCallback: async (currentData) => {
            if (
              currentData?.name === PAYMENT_CHECKOUT.CHECKOUT_CLOSED &&
              (currentData?.data?.status === PAYMENT_CHECKOUT.STATUS.DRAFT ||
                currentData?.data?.status === PAYMENT_CHECKOUT.STATUS.READY)
            ) {
              cancelSubscription();
              setWaitForSuccess(false);
            }

            /* check for  payment status */
            if (
              currentData?.data?.status === PAYMENT_CHECKOUT.STATUS.COMPLETED
            ) {
              if (currentData?.name === PAYMENT_CHECKOUT.CHECKOUT_COMPLETED) {
                /* trigger upgraded to pro event only once */
                GaEvent(GA_EVENT_NAMES.UPGRADED_TO_PRO);
              }
              const userResponse = await getUser(); /* get user data */

              if (userResponse?.data?.userProfile) {
                setWaitForSuccess(false); /* set loader false */
              }
            }
          },
        }).then((Paddle) => {
          /* Paddle checkout function to open checkout modal */
          Paddle.Checkout.open({
            transactionId:
              res?.subscribePaddle
                ?.id /* transaction id for checkout initiate */,
          });
        });
      }
    },
    onError() {
      setWaitForSuccess(false);
    },
  });

  return (
    <>
      <LoaderComponent spinning={loading} setHeight={100}>
        <div className="pricing-table-container d-flex flex-vertical align-center justify-center full-height">
          <Portal portalId="header-title">
            <div className="full-height">
              <CommonLogo src="/logo-light.png" classes="full-logo" />
            </div>
          </Portal>
          <Portal portalId="profile">
            <UserProfile />
          </Portal>

          <div className=" text-center mt-10">
            <h1 className="pricing-heading">InstaPlug Pricing Plans</h1>
          </div>

          {data?.length > 0 && (
            <PricingTable
              headers={headers}
              data={data}
              registerCustomer={registerCustomer}
              subscribeToPlan={subscribeToPlan}
              setWaitForSuccess={setWaitForSuccess}
              waitForSuccess={waitForSuccess}
            />
          )}
        </div>
      </LoaderComponent>
    </>
  );
}

export default PricingList;
