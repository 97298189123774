import { Card, Col, Input, InputNumber, message, Radio, Row } from 'antd';
import { map } from 'lodash';
import React, { useContext, useMemo } from 'react';
import { AppContext } from '../../AppContext';
import { PremiumIcon } from '../../assets/svg';
import { COL_ROW_OPTIONS } from '../../common/constants';
import InfoTooltip from '../../components/InfoTooltip';
import useFeatureAccess from '../FeatureAccess/hooks/useFeatureAccess';

function ColRow() {
  const {
    state: { previewLayoutObject, columnMode, openFeatureAccessModal },
    handlePreviewLayout,
    handleColumnMode,
    toggleFeatureAccessModal,
  } = useContext(AppContext);

  const { widgetAccessConfig: hasAccessTo } = useFeatureAccess({
    contextToUse: AppContext,
  });

  const isManual = useMemo(
    () => columnMode === COL_ROW_OPTIONS?.MANUAL?.value,
    [columnMode],
  );

  const handleColRowSelection = (mode) => {
    const {
      target: { value = null },
    } = mode;
    if (value === COL_ROW_OPTIONS?.AUTO?.value) {
      handlePreviewLayout({ ...previewLayoutObject, col: 4 });
    }
    handleColumnMode(value);
  };

  const handleColumnChange = (col) => {
    if (col < 1) {
      message?.info('Column can not be less than 1.');
      return;
    }
    if (col > 10) {
      message?.info('Max Columns reached.');
      return;
    }

    handlePreviewLayout({ ...previewLayoutObject, col });
  };

  const handleRowChange = (row) => {
    if (row < 1) {
      message?.info('Row can not be less than 1.');
      return;
    }
    if (row > 5) {
      message?.info('Max Rows reached.');
      return;
    }

    handlePreviewLayout({ ...previewLayoutObject, row });
  };

  const handleGapChange = (gap) => {
    handlePreviewLayout({ ...previewLayoutObject, gap });
  };

  const handleWidthChange = (event) => {
    const {
      target: { value },
    } = event;
    if (!hasAccessTo?.allowAdjustableWidgetWidth) {
      toggleFeatureAccessModal({
        ...openFeatureAccessModal,
        status: true,
        title: 'Control the width of the Widget',
        info: 'Set the width of the widget.',
      });
      handlePreviewLayout({ ...previewLayoutObject, width: 'auto' });
      return;
    }
    handlePreviewLayout({ ...previewLayoutObject, width: value });
  };
  return (
    <div>
      <Card title="Column mode" className="full-width" bordered={false}>
        <center>
          <Radio.Group
            options={map(COL_ROW_OPTIONS, (mode) => mode)}
            optionType="button"
            buttonStyle="solid"
            onChange={handleColRowSelection}
            defaultValue={columnMode}
          />
        </center>
        {isManual && (
          <>
            <Card size="small" className="mt-24 mb-8">
              <Row align="middle">
                <Col span={17}>Column</Col>
                <Col span={7}>
                  <InputNumber
                    className="full-width"
                    type="number"
                    value={previewLayoutObject?.col}
                    maxLength={2}
                    onChange={handleColumnChange}
                  />
                </Col>
              </Row>
            </Card>
          </>
        )}

        <Card size="small" className="mt-8 mb-8">
          <Row align="middle">
            <Col span={17}>Row</Col>
            <Col span={7}>
              <InputNumber
                className="full-width"
                type="number"
                value={previewLayoutObject?.row}
                maxLength={2}
                onChange={handleRowChange}
              />
            </Col>
          </Row>
        </Card>

        <Card size="small" className="mt-8 mb-8">
          <Row align="middle">
            <Col span={17}>
              Gap
              <InfoTooltip />
            </Col>
            <Col span={7}>
              <InputNumber
                className="full-width"
                type="number"
                value={previewLayoutObject?.gap}
                maxLength={2}
                onChange={handleGapChange}
              />
            </Col>
          </Row>
        </Card>

        <Card size="small" className="mt-8 mb-8">
          <Row align="middle">
            <Col span={17} className="d-flex align-center">
              <div className="d-flex align-center col-gap-6">
                {!hasAccessTo?.allowAdjustableWidgetWidth && <PremiumIcon />}
                <span className="d-block mt-2">Width</span>
              </div>
              <InfoTooltip />
            </Col>
            <Col span={7}>
              <Input
                onChange={handleWidthChange}
                value={previewLayoutObject?.width}
              />
            </Col>
          </Row>
        </Card>
      </Card>
    </div>
  );
}

export default ColRow;
