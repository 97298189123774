import { EditOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Col,
  Form,
  Input,
  Layout,
  Modal,
  Row,
  Typography,
  message,
} from 'antd';
import { Content } from 'antd/es/layout/layout';
import { uniqBy } from 'lodash';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { DesktopIcon, MobileIcon } from '../../assets/svg';
import {
  GA_EVENT_NAMES,
  GA_EVENT_PARAMETERS,
  LAYOUT_OPTIONS,
  MAX_POST_LIMIT,
  ROUTES,
  SOURCE_TYPE,
} from '../../common/constants';
import { GaEvent, formValidatorRules } from '../../common/utils';
import CommonLogo from '../../components/CommonLogo';
import DisplayTextWithLinks from '../../components/DisplayTextWithLinks';
import LoaderComponent from '../../components/LoaderComponent';
import Portal from '../../components/Portal';
import { UPDATE_WIDGET } from '../Dashboard/graphql/Mutations';
import {
  BUSINESS_ACCOUNT_MEDIA,
  BUSINESS_PROFILE,
  GET_PERSONAL_MEDIA,
  GET_PERSONAL_PROFILE_HEADER,
  GET_SOURCE,
} from '../Source/graphql/Queries';
import Frame from './Frame';
import WidgetPreview from './WidgetPreview';
import WidgetSidebar from './WidgetSidebar';
import { PUBLISH_WIDGET } from './graphql/Mutations';
import {
  DEFAULT_MEDIA_DATA,
  DEFAULT_PROFILE_DATA,
  SINGLE_WIDGET,
} from './graphql/Queries';

const { required } = formValidatorRules;

function Widget() {
  const {
    state: {
      feedTitle,
      previewLayoutObject: { col, row },
      previewStyle,
      disablePublishButton,
      columnMode,
      postData,
      isAccountConnected,
    },
    dispatch,
    disablePublishAction,
    handlePreviewLayout,
    handlePostData,
    handlePostCount,
    handleAccountConnectionStatus,
  } = useContext(AppContext);
  const history = useHistory();
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [widgetData, setWidget] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  const [sourceData, setSourceData] = useState(null);
  const [accountMedia, setAccountMedia] = useState([]);
  const [sourceType, setSourceType] = useState('');
  const [headerData, setHeaderData] = useState(null);
  const [filters, setFilters] = useState({
    show: [],
    hide: [],
  });

  const [businessSources, setBusinessSources] = useState([]);

  const businessAccount = useMemo(
    () => sourceType === SOURCE_TYPE?.BUSINESS_ACCOUNT?.value,
    [sourceType],
  );
  const personalAccount = useMemo(
    () => sourceType === SOURCE_TYPE?.PERSONAL_ACCOUNT?.value,
    [sourceType],
  );

  const [getDefaultProfileData] = useLazyQuery(DEFAULT_PROFILE_DATA, {
    onCompleted(res) {
      setHeaderData(res?.defaultProfileData);
    },
    onError() {},
  });

  const [
    fetchDefaultMediaData,
    { loading: defaultMediaLoading },
  ] = useLazyQuery(DEFAULT_MEDIA_DATA, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      const data = uniqBy([...postData, ...res?.defaultMediaData?.data], 'id');
      handlePostData(data);
      handlePostCount(res?.defaultMediaData?.count);
    },
    onError() {},
  });

  const [fetchPersonalData, { loading: personalMediaLoading }] = useLazyQuery(
    GET_PERSONAL_MEDIA,
    {
      fetchPolicy: 'network-only',
      onCompleted(res) {
        dispatch({
          type: 'SET_POST_DATA',
          data: uniqBy([...postData, ...res?.getPersonalMediaData?.data], 'id'),
        });
        dispatch({
          type: 'SET_POST_COUNT',
          data: res?.getPersonalMediaData?.count,
        });
      },
      onError() {},
    },
  );

  const [getBusinessData, { loading: loadingBusinessMedia }] = useLazyQuery(
    BUSINESS_ACCOUNT_MEDIA,
    {
      fetchPolicy: 'network-only',
      onCompleted(res) {
        dispatch({
          type: 'SET_POST_DATA',
          data: uniqBy([...postData, ...res?.businessMediaData?.data], 'id'),
        });
        dispatch({
          type: 'SET_POST_COUNT',
          data: res?.businessMediaData?.count,
        });
      },
      onError() {},
    },
  );
  const [getHeader, { loading: personalHeaderLoading }] = useLazyQuery(
    GET_PERSONAL_PROFILE_HEADER,
    {
      fetchPolicy: 'network-only',
      onCompleted(res) {
        setHeaderData(res?.personalProfileData);
      },
      onError() {
        setHeaderData(null);
      },
    },
  );

  const [getBusinessProfile] = useLazyQuery(BUSINESS_PROFILE, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setHeaderData(res?.businessProfileData?.iGAccount);
    },
    onError() {
      setHeaderData(null);
    },
  });

  const returnLimit = useMemo(
    () =>
      previewStyle === LAYOUT_OPTIONS?.SLIDER?.value
        ? MAX_POST_LIMIT
        : col * row,
    [previewStyle, filters, columnMode, col, row],
  );

  const pagination = useMemo(
    () => ({
      offset: 0,
      limit: returnLimit,
    }),
    [previewStyle, businessAccount, personalAccount, returnLimit],
  );

  useEffect(() => {
    if (sourceData?.sourceId) {
      if (personalAccount) {
        fetchPersonalData({
          variables: {
            sourceId: sourceData?.sourceId,
            pagination,
          },
        });
        return;
      }

      if (businessAccount) {
        getBusinessData({
          variables: {
            sourceId: sourceData?.sourceId,
            pagination,
          },
        });
        return;
      }

      getDefaultProfileData();
    }
  }, [sourceData, sourceType]);

  const getDifferentMediaData = (payloadId, type) => {
    switch (type) {
      case SOURCE_TYPE?.PERSONAL_ACCOUNT?.value:
        fetchPersonalData({
          variables: {
            sourceId: payloadId,
            pagination,
          },
        });
        getHeader({
          variables: {
            sourceId: payloadId,
          },
        });
        break;
      case SOURCE_TYPE?.BUSINESS_ACCOUNT?.value:
        getBusinessData({
          variables: {
            sourceId: payloadId,
            pagination,
          },
        });
        getBusinessProfile({
          variables: {
            sourceId: payloadId,
          },
        });
        break;
      default:
        break;
    }
  };

  const [getSource] = useLazyQuery(GET_SOURCE, {
    fetchPolicy: 'network-only',
    onCompleted(sourceRes) {
      handleAccountConnectionStatus(sourceRes?.getSource);
      disablePublishAction(false);
      if (!sourceRes?.getSource) {
        dispatch({ type: 'SET_POST_DATA', data: [] });
        dispatch({ type: 'SET_POST_COUNT', data: 0 });
        fetchDefaultMediaData({
          variables: {
            widgetId: id,
            pagination,
          },
        });
        getDefaultProfileData();
        return;
      }
      const { sourceId, sourceType: currentSourceType, igSources } =
        sourceRes?.getSource || {};

      dispatch({ type: 'SET_POST_DATA', data: [] });
      dispatch({ type: 'SET_POST_COUNT', data: 0 });
      setSourceData(sourceRes?.getSource);
      setBusinessSources(sourceRes?.getSource?.businessSources);
      getDifferentMediaData(sourceId, currentSourceType, igSources);
    },
    onError() {},
  });

  const [getWidget, { loading: widgetLoading }] = useLazyQuery(SINGLE_WIDGET, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setWidget(res?.getWidget);
      setSourceType(res?.getWidget?.selectedSourceType);
      const { __typename, ...filtersData } = res?.getWidget?.filters || {};
      setFilters(
        filtersData?.show || filtersData?.hide
          ? filtersData
          : { show: [], hide: [], totalNumberOfPost: 0 },
      );
      if (!sourceType) {
        getSource({
          variables: {
            where: {
              sourceType: res?.getWidget?.selectedSourceType,
              widgetId: id,
            },
          },
        });
      }
    },
  });

  const [updateWidgets, { loading: updateWidgetLoading }] = useMutation(
    UPDATE_WIDGET,
    {
      fetchPolicy: 'network-only',
      onCompleted() {
        getWidget({
          variables: {
            widgetId: id,
          },
        });
      },
      onError() {},
    },
  );

  useEffect(() => {
    getWidget({
      variables: {
        widgetId: id,
      },
    });
  }, []);

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender?.current) {
      isFirstRender.current = false;
      return;
    }

    if (sourceType && id) {
      getSource({
        variables: {
          where: {
            sourceType,
            widgetId: id,
          },
        },
      });
    }
  }, [sourceType, id, col, row, previewStyle]);

  const handleClickOnWidgetName = () => {
    setOpen(true);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  const handleFinish = (value) => {
    GaEvent(GA_EVENT_NAMES.RENAME_WIDGET, {
      [GA_EVENT_PARAMETERS.WIDGET_ID]: id,
    });
    updateWidgets({
      variables: {
        where: {
          id,
        },
        data: value,
      },
    });
    setOpen(false);
  };

  const [publishWidget] = useMutation(PUBLISH_WIDGET, {
    onCompleted() {},
    onError() {},
  });

  const handlePublishWidget = async () => {
    try {
      const response = await publishWidget({
        variables: { where: { widgetId: id, sourceType } },
      });
      if (response) {
        disablePublishAction(true);
        GaEvent(GA_EVENT_NAMES.PUBLISH_WIDGET, {
          [GA_EVENT_PARAMETERS.WIDGET_ID]: id,
        });
      }
    } catch (error) {
      history?.push(ROUTES?.MAIN);
      message?.error('Error publishing widget!');
    }
  };
  const handleClose = () => {
    GaEvent(GA_EVENT_NAMES.CLOSE_WIDGET, {
      [GA_EVENT_PARAMETERS.WIDGET_ID]: id,
    });
    history?.push(ROUTES?.MAIN);
    handlePreviewLayout({});
  };

  const initialValues = {
    name: widgetData?.name,
  };

  const returnDifferentFetchMore = () => {
    if (sourceData) {
      if (personalAccount) {
        return fetchPersonalData;
      }
      if (businessAccount) {
        return getBusinessData;
      }
    }
    return fetchDefaultMediaData;
  };

  return (
    <Layout hasSider>
      <Portal portalId="header-title">
        <Row gutter={12}>
          <Col>
            <div
              className="full-height d-flex align-center"
              onClick={() => handlePreviewLayout({})}
            >
              <CommonLogo src="/logo192.png" />
            </div>
          </Col>
          <div className="pl-18">
            <Button
              type="link"
              onClick={handleClickOnWidgetName}
              size="large"
              className="text-color widget-name-button"
              icon={<EditOutlined />}
            >
              {widgetData?.name}
            </Button>
          </div>
        </Row>
      </Portal>

      <Portal portalId="profile">
        <Button
          onClick={handleClose}
          className="ml-8 all-btn font-bold"
          type="primary"
          danger
        >
          Close
        </Button>
      </Portal>
      <Portal portalId="publish">
        <div className="d-flex col-gap-8">
          {isAccountConnected && (
            <Button
              onClick={handlePublishWidget}
              type="primary"
              className="all-btn font-bold"
              disabled={disablePublishButton}
            >
              Publish
            </Button>
          )}
          <Button
            onClick={() => {
              GaEvent(GA_EVENT_NAMES.CLICK_ON_WIDGET_VIEW_PLANS);
              history?.push(ROUTES.MANAGE_PRICING);
            }}
            type="default"
            className="all-btn upgrade-btn"
          >
            View plans
          </Button>
        </div>
      </Portal>

      {open && (
        <Modal
          title="Rename widget"
          open={open}
          footer={null}
          onCancel={handleCancel}
          closable={false}
          className="rename-widget"
          width={400}
          // eslint-disable-next-line no-undef
          getContainer={() => document?.getElementById('root')}
        >
          <Form
            onFinish={handleFinish}
            initialValues={initialValues}
            layout="vertical"
          >
            <Form.Item
              name="name"
              label="Widget name"
              rules={[{ ...required, message: 'Please enter widget name!' }]}
            >
              <Input allowClear disabled={updateWidgetLoading} />
            </Form.Item>
            <Form.Item>
              <div className="d-flex col-gap-6">
                <Button
                  type="primary"
                  danger
                  onClick={handleCancel}
                  className="full-width all-btn btn-rounded"
                  disabled={updateWidgetLoading}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="full-width all-btn btn-rounded"
                  loading={updateWidgetLoading}
                >
                  Save
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Modal>
      )}

      <div className="widget-side-bar">
        <WidgetSidebar
          setWidget={setWidget}
          widgetData={widgetData}
          sourceData={sourceData}
          accountMedia={accountMedia}
          setAccountMedia={setAccountMedia}
          getWidget={getWidget}
          getSource={getSource}
          sourceType={sourceType}
          setSourceType={setSourceType}
          getBusinessLoading={loadingBusinessMedia}
          personalHeaderLoading={personalHeaderLoading}
          headerData={headerData}
          setHeaderData={setHeaderData}
          accountType={sourceType}
          setSourceData={setSourceData}
          filters={filters}
          setFilters={setFilters}
          updateWidgets={updateWidgets}
          businessSources={businessSources}
          setBusinessSources={setBusinessSources}
        />
      </div>

      <Layout>
        <Content>
          <div className={`preview-screen ${isMobile ? 'pt-32 pb-32' : ''} `}>
            <Button
              className="toggle-screen p-0"
              onClick={() => setIsMobile(!isMobile)}
              icon={isMobile ? <DesktopIcon /> : <MobileIcon />}
            />
            <Frame
              className={`preview-frame ${isMobile ? 'mobile-screen' : ''}`}
            >
              {feedTitle && (
                <div className="d-flex justify-center align-center font-bold font-2xl mt-12 mb-12">
                  <Typography.Text className="font-2xl">
                    <DisplayTextWithLinks text={feedTitle} />
                  </Typography.Text>
                </div>
              )}
              <LoaderComponent spinning={widgetLoading}>
                <WidgetPreview
                  sourceType={sourceType}
                  headerData={headerData}
                  fetchMore={returnDifferentFetchMore()}
                  mediaFetchLimit={returnLimit}
                  isMobile={isMobile}
                  sourceData={sourceData}
                  loading={
                    defaultMediaLoading ||
                    personalMediaLoading ||
                    loadingBusinessMedia
                  }
                />
              </LoaderComponent>
            </Frame>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default Widget;
