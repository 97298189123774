import { LeftOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Card, Col, Form, Input, message, Row } from 'antd';
import React, { useContext, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import UserProfile from '../../app/components/header/UserProfile';
import { AppContext } from '../../AppContext';
import { REGEX, USER } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import CommonLogo from '../../components/CommonLogo';
import LoaderComponent from '../../components/LoaderComponent';
import Portal from '../../components/Portal';
import { CHANGE_PASSWORD, EDIT_PROFILE } from '../auth/graphql/Mutations';

const { required, name, email } = formValidatorRules;

function Profile() {
  const [btnLoading, setBtnLoading] = useState(false);
  const { id } = useParams();
  const [form] = Form?.useForm();
  const history = useHistory();
  const inputRef = useRef();

  // eslint-disable-next-line no-undef
  const getUser = localStorage?.getItem(USER);
  const getAuthProvider = JSON?.parse(getUser);
  const checkProvider = getAuthProvider?.authProvider;
  const { getCurrentUser } = useContext(AppContext);
  const user = getCurrentUser();
  const [isEdited, setIsEdited] = useState(true);

  const [changePassword, { loading: passwordLoading }] = useMutation(
    CHANGE_PASSWORD,
    {
      onCompleted() {
        form?.resetFields();
      },
      onError() {},
    },
  );

  const [editProfile, { loading: profileLoading }] = useMutation(EDIT_PROFILE, {
    onCompleted() {
      setIsEdited(true);
    },
    onError() {},
  });
  const profileInitialValues = id
    ? {
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
      }
    : { firstName: '', lastName: '', email: '' };

  const handleBack = () => {
    history?.goBack();
  };

  const onFinish = (values) => {
    setBtnLoading(true);
    editProfile({
      variables: {
        details: {
          firstName: values?.firstName,
          lastName: values?.lastName,
        },
      },
    });
    setBtnLoading(false);
  };
  const handleChangePassword = (password) => {
    if (password?.newPassword === password?.confirmPassword) {
      changePassword({
        variables: {
          data: {
            newPassword: password?.newPassword,
          },
        },
      });
    } else {
      message.error('New and Confirm password does not match');
      inputRef?.current?.focus({
        cursor: 'all',
      });
    }
  };

  const saveStateForFirstName = (firstName) => {
    setIsEdited(firstName?.target?.value === user?.firstName);
  };
  const saveStateForLastName = (lastName) => {
    setIsEdited(lastName?.target?.value === user?.lastName);
  };

  if (btnLoading) {
    return <LoaderComponent />;
  }
  const colSize = {
    xs: 24,
    md: checkProvider === 'CUSTOM' ? 12 : 24,
    lg: checkProvider === 'CUSTOM' ? 12 : 24,
    xl: checkProvider === 'CUSTOM' ? 10 : 24,
  };
  return (
    <div className="profile-container">
      <div className="">
        <Button
          icon={<LeftOutlined />}
          type="link"
          className="all-btn leading-1"
          onClick={() => history?.goBack()}
        >
          Go back
        </Button>
      </div>
      <Portal portalId="header-title">
        <CommonLogo src="/logo-light.png" classes="full-logo" />
      </Portal>
      <Portal portalId="profile">
        <UserProfile />
      </Portal>
      <div
        className={[
          checkProvider === 'CUSTOM'
            ? 'card-container card-container-width'
            : 'card-container card-container-width-custom-login',
        ]}
      >
        <Row gutter={[32, 32]} justify="center">
          <Col {...colSize}>
            <Form
              className="sticky-action-form"
              layout="vertical"
              initialValues={profileInitialValues}
              onFinish={onFinish}
              size="middle"
            >
              <Card
                className="profile-card"
                title={
                  <div className="d-flex align-center justify-between">
                    <h1 className="profile-card-heading">Profile</h1>
                    <div className="profile-btn d-flex col-gap-8 align-center full-height">
                      <Form.Item className="m-0">
                        <Button
                          onClick={handleBack}
                          disabled={btnLoading || profileLoading}
                          size="middle"
                          danger
                          type="primary"
                          className="all-btn"
                        >
                          Cancel
                        </Button>
                      </Form.Item>
                      <Form.Item className="m-0">
                        <Button
                          type="primary"
                          loading={btnLoading || profileLoading}
                          disabled={isEdited}
                          htmlType="submit"
                          size="middle"
                          className="all-btn"
                        >
                          Save
                        </Button>
                      </Form.Item>
                    </div>
                  </div>
                }
              >
                <div className="card-body-wrapper">
                  <Form.Item
                    name="firstName"
                    label="First Name"
                    rules={[
                      { ...required, message: 'Please Enter First Name' },
                      name,
                    ]}
                  >
                    <Input
                      disabled={profileLoading}
                      placeholder="Enter First Name"
                      allowClear
                      onChange={saveStateForFirstName}
                    />
                  </Form.Item>

                  <Form.Item
                    name="lastName"
                    label="Last Name"
                    rules={[
                      { ...required, message: 'Please Enter Last Name' },
                      name,
                    ]}
                  >
                    <Input
                      disabled={profileLoading}
                      placeholder="Enter Last Name"
                      allowClear
                      onChange={saveStateForLastName}
                    />
                  </Form.Item>

                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                      { ...required, message: 'Please Enter Email' },
                      email,
                    ]}
                  >
                    <Input disabled placeholder="Enter Email" />
                  </Form.Item>
                </div>
              </Card>
            </Form>
          </Col>
          {checkProvider === 'CUSTOM' && (
            <Col {...colSize}>
              <Form
                form={form}
                size="middle"
                layout="vertical"
                onFinish={handleChangePassword}
              >
                <Card
                  className="profile-card"
                  title={
                    <div className="d-flex align-center justify-between">
                      <h1 className="profile-card-heading">Change Password</h1>
                      <div className="profile-btn d-flex col-gap-8 align-center full-height">
                        <Button
                          onClick={handleBack}
                          disabled={btnLoading || passwordLoading}
                          size="middle"
                          danger
                          type="primary"
                          className="all-btn"
                        >
                          Cancel
                        </Button>

                        <Button
                          type="primary"
                          loading={btnLoading || passwordLoading}
                          htmlType="submit"
                          size="middle"
                          className="all-btn"
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  }
                >
                  <div className="card-body-wrapper">
                    <Form.Item
                      name="newPassword"
                      label="New Password"
                      rules={[
                        { ...required, message: 'Please enter new password' },
                        {
                          pattern: REGEX?.PASSWORD,
                          validator: (_, newPassword) => {
                            if (newPassword && newPassword?.length < 8) {
                              return Promise?.reject(
                                new Error('Password is too short'),
                              );
                            }
                            return Promise?.resolve();
                          },
                        },
                      ]}
                    >
                      <Input.Password
                        disabled={passwordLoading}
                        placeholder="Enter new password"
                        allowClear
                      />
                    </Form.Item>

                    <Form.Item
                      name="confirmPassword"
                      label="Confirm Password"
                      rules={[
                        {
                          ...required,
                          message: 'Please re-enter password',
                        },
                      ]}
                    >
                      <Input.Password
                        ref={inputRef}
                        disabled={passwordLoading}
                        placeholder="Re-enter password"
                        allowClear
                      />
                    </Form.Item>
                  </div>
                </Card>
              </Form>
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
}
export default Profile;
