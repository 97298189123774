import {
  CloseCircleOutlined,
  InstagramOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { Carousel, Image } from 'antd';
import { includes, map } from 'lodash';
import React, {
  useCallback,
  useContext,
  useLayoutEffect,
  useState,
} from 'react';
import { NextIcon, PrevIcon, RightArrow } from '../../../assets/svg';
import { MEDIA_TYPE } from '../../../common/constants';
import CustomVideoPlayer from './CustomVideoPlayer';
import PostFooter from './PostFooter';
import PostHeader from './PostHeader';

const CustomPostModal = ({ children, onCancel, handleScroll }) => (
  <div className="custom-modal post-preview-modal">
    <div
      className="custom-modal-wrapper"
      onClick={() => onCancel()}
      onScroll={handleScroll}
    >
      <div
        className="modal-inner-wrapper"
        onClick={(e) => e?.stopPropagation()}
      >
        {children}
      </div>
    </div>
  </div>
);

const PostBody = ({ data }) => (
  <div className="post-layout-body">
    {data?.mediaType === MEDIA_TYPE?.VIDEO?.value ? (
      <CustomVideoPlayer url={data?.mediaUrl} />
    ) : (
      <Image
        className="instagram-post-image"
        src={data?.mediaUrl}
        preview={false}
      />
    )}
  </div>
);

const PostLayout = ({
  data = [],
  controlPostElement,
  callToAction = [],
  id = null,
  headerData = null,
  language = '',
  col,
  popUPSettings,
  context,
}) => {
  const splitUrl = (url) => {
    const splitString = url?.split('/');
    return splitString?.[splitString?.length - 2];
  };

  const buttonAdded = (postLink) => {
    splitUrl(postLink);
    return callToAction?.find(
      (item) => splitUrl(item?.instagramUrl) === splitUrl(postLink),
    );
  };

  const settings = {
    className: 'post-slider common-carousel post-preview-modal-carousel',
    infinite: true,
    speed: 500,
    slidesPerRow: 1,
    slidesToShow: 1,
    arrows: true,
    dots: false,
  };

  return (
    <div className="post-layout" id={`post-${id}`}>
      {controlPostElement('user') ? (
        <PostHeader
          profile={headerData}
          postData={data}
          controlPostElement={controlPostElement}
          language={language}
          col={col}
          inModal
          context={context}
        />
      ) : (
        <>
          {controlPostElement('instagramLink') && (
            <div className="post-secondary-header pointer">
              <a
                href={
                  data?.username
                    ? `https://www.instagram.com/${data?.username}/`
                    : data?.permalink
                }
                target="_blank"
                rel="noreferrer"
                aria-label="username"
              >
                <InstagramOutlined className="instagram-icon" />
              </a>
            </div>
          )}
        </>
      )}

      {data?.children ? (
        <Carousel
          {...settings}
          draggable
          nextArrow={
            <div>
              <div className="next-arrow d-flex align-center full-height">
                <NextIcon className="slider-arrow-icon" />
              </div>
            </div>
          }
          prevArrow={
            <div>
              <div className="prev-arrow d-flex align-center full-height">
                <PrevIcon className="slider-arrow-icon" />
              </div>
            </div>
          }
        >
          {map(data?.children, (item) => (
            <PostBody data={item} />
          ))}
        </Carousel>
      ) : (
        <PostBody data={data} />
      )}

      {buttonAdded(data?.permalink) && (
        <a
          className="call-to-action-active-button d-flex justify-between align-center"
          href={buttonAdded(data?.permalink)?.buttonUrl}
          target="_blank"
          rel="noreferrer"
        >
          <span>{buttonAdded(data?.permalink)?.buttonLabel}</span>
          <RightArrow className="right-arrow" />
        </a>
      )}
      <div className="post-layout-footer">
        <PostFooter
          postData={data}
          controlPostElement={controlPostElement}
          inModal
          col={col}
          popUPSettings={popUPSettings}
          context={context}
        />
      </div>
    </div>
  );
};

function PostPreviewModal({
  open = false,
  setOpen,
  profile = null,
  currentPostId,
  context,
  headerData = null,
  language = '',
  fetchMore,
  widgetId,
}) {
  const {
    state: {
      postModule,
      feedTitle,
      previewLayoutObject: { col, row },
      columnMode,
      postData,
      postCount,
    },
  } = useContext(context);
  const [newDataLoading, setNewDataLoading] = useState(false);
  const [modalMedia, setModalMedia] = useState(postData);
  const controlPopUpElement = (section) =>
    includes(postModule?.popUPSettings, section);

  const getNewDataOnEndOfSlide = useCallback(async () => {
    await fetchMore({
      variables: {
        widgetId,
        pagination: {
          limit: col * row,
          offset: postData?.length,
        },
      },
    });
  }, [col, postData, fetchMore, columnMode, modalMedia]);

  const handleScroll = useCallback(
    (e) => {
      const element = e.target;
      if (
        element.scrollTop + element.clientHeight === element.scrollHeight &&
        postCount > postData?.length
      ) {
        setNewDataLoading(true);
        getNewDataOnEndOfSlide();
      }
    },
    [getNewDataOnEndOfSlide],
  );

  useLayoutEffect(() => {
    if (open && currentPostId) {
      // eslint-disable-next-line no-undef
      const iframe = document?.querySelector(`#previewIframe`);
      if (iframe) {
        const postDiv = iframe?.contentWindow?.document?.querySelector(
          `#post-${currentPostId}`,
        );
        if (postDiv) {
          postDiv?.scrollIntoView();
        }
      }
      // eslint-disable-next-line no-undef
      const postDiv = document?.querySelector(`#post-${currentPostId}`);
      if (postDiv) {
        postDiv?.scrollIntoView();
      }
    }
    return () => {
      setModalMedia([]);
    };
  }, [open, currentPostId]);

  return (
    <CustomPostModal
      open={open}
      onCancel={() => setOpen(!open)}
      handleScroll={handleScroll}
    >
      <div className="close-custom-modal d-flex align-center">
        <CloseCircleOutlined
          className="close-icon pointer"
          onClick={() => setOpen(!open)}
        />
        <div className="feed-title font-2xl text-center">{feedTitle}</div>
      </div>
      {map(modalMedia, (data) => (
        <PostLayout
          key={data?.id}
          id={data?.id}
          data={data}
          profile={profile}
          callToAction={postModule?.callToAction}
          controlPostElement={controlPopUpElement}
          headerData={headerData}
          language={language}
          col={col}
          popUPSettings={postModule?.popUPSettings}
          context={context}
        />
      ))}
      {newDataLoading && (
        <div className="full-width d-flex align-center justify-center pb-12">
          <LoadingOutlined className="font-3xl" />
        </div>
      )}
    </CustomPostModal>
  );
}

export default PostPreviewModal;
