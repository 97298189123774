import { Layout } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../AppContext';
import { BREAKPOINTS } from '../common/constants';
import RouterPrompt from '../components/RouterPrompt';
import '../i18n';
import AppHeader from './components/header/AppHeader';
import ContentRoutes from './ContentRoutes';

const { Content } = Layout;
const App = () => {
  const { state } = useContext(AppContext);
  const [isDesktop, setDesktop] = useState(
    // eslint-disable-next-line no-undef
    window.innerWidth > BREAKPOINTS.tablet,
  );
  const [isActive, setActive] = useState(false);

  const handleOverlay = () => {
    setActive(!isActive);
  };

  useEffect(() => {
    const updateMedia = () => {
      // eslint-disable-next-line no-undef
      if (window.innerWidth > BREAKPOINTS.tablet) {
        setDesktop(true);
      } else {
        setDesktop(false);
      }
    };
    // eslint-disable-next-line no-undef
    window.addEventListener('resize', updateMedia);
    // eslint-disable-next-line no-undef
    return () => window.removeEventListener('resize', updateMedia);
  }, []);

  return (
    <Layout className="main-wrapper">
      <AppHeader>
        <div
          id="header-title"
          className="header-height text-bold font-large full-width d-flex align-center"
        />
        <div id="publish" className="text-bold font-large" />
        <div id="profile" className="text-bold font-large" />
      </AppHeader>
      <Layout className="full-width d-flex full-height">
        <RouterPrompt openPrompt={state?.showPrompt} />
        {!isDesktop && (
          <span
            className={
              isActive ? 'active overlay-responsive' : 'overlay-disable'
            }
            onClick={handleOverlay}
          />
        )}

        <Layout>
          <Content className="instaplug-content-container">
            <ContentRoutes />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default App;
