import { gql } from '@apollo/client';

export const SINGLE_WIDGET = gql`
  query GetWidget($widgetId: ID!) {
    getWidget(widgetId: $widgetId) {
      id
      name
      createdAt
      updatedAt
      template
      filters {
        show
        hide
        totalNumberOfPost
      }
      language
      selectedSourceType
    }
  }
`;

export const DEFAULT_PROFILE_DATA = gql`
  query defaultProfileData {
    defaultProfileData {
      id
      mediaCount
      username
      followersCount
      followsCount
      website
      profilePictureUrl
      name
      igId
      biography
    }
  }
`;

export const DEFAULT_MEDIA_DATA = gql`
  query defaultMediaData($widgetId: ID!, $pagination: PaginationInput) {
    defaultMediaData(widgetId: $widgetId, pagination: $pagination) {
      data {
        likeCount
        commentsCount
        children {
          permalink
          thumbnailUrl
          mediaUrl
          mediaType
          id
        }
        username
        timestamp
        permalink
        thumbnailUrl
        mediaUrl
        mediaType
        id
        caption
      }
      count
    }
  }
`;

export const GET_LAYOUT = gql`
  query GetLayout($where: GetLayoutWhere!) {
    getLayout(where: $where) {
      type
      sliderSettings {
        arrowControl
        dragControl
        animationSpeed
        autoPlay
      }
      columnsAndRows {
        mode
        config {
          columns
          rows
          gap
          width
        }
      }
      feedTitle
      headers {
        showHeaders
        config {
          profilePicture
          fullName
          userName
          verifiedBadge
          postsCount
          followersCount
          followingCount
          followButton
        }
      }
    }
  }
`;

export const GET_POST_CONFIG = gql`
  query getPostConfig($where: GetPostConfigWhere!) {
    getPostConfig(where: $where) {
      style
      postElement {
        user
        date
        likeCount
        commentCount
        text
        instagramLink
        share
      }
      clickAction
      popUPSettings {
        user
        location
        followButton
        instagramLink
        likesCount
        share
        text
        comments
        date
      }
      callToAction {
        instagramUrl
        buttonUrl
        buttonLabel
      }
      postCustomStyle {
        fontColor
        fontFamily
        margin
        padding
        isDarkMode
        borderStyle
        borderColor
        borderWidth
      }
    }
  }
`;
