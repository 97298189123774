import {
  ApiFilled,
  LayoutFilled,
  PictureFilled,
  SettingFilled,
} from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Tabs } from 'antd';
import React, { useContext, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import {
  POST_ACTION,
  POST_STYLE,
  notAllowedKeys,
} from '../../common/constants';
import FeatureAccessModal from '../FeatureAccess/access-prompts/FeatureAccessModal';
import useFeatureAccess from '../FeatureAccess/hooks/useFeatureAccess';
import WidgetLayout from '../Layout/WidgetLayout';
import WidgetPost from '../Post/WidgetPost';
import Settings from '../Settings/Settings';
import Source from '../Source/Source';
import { DISCONNECT_INSTAGRAM_ACCOUNT } from '../Source/graphql/Mutations';
import { GET_LAYOUT, GET_POST_CONFIG } from './graphql/Queries';

function WidgetSidebar({
  accountType,
  sourceData,
  accountMedia,
  setAccountMedia,
  getWidget,
  setWidget,
  widgetData,
  getSource,
  getHeader,
  getBusinessProfile,
  personalHeaderLoading,
  getBusinessLoading,
  sourceType,
  setSourceType,
  headerData = null,
  setHeaderData,
  setSourceData,
  filters = { show: [], hide: [] },
  setFilters,
  updateWidgets,
  businessSources = [],
  setBusinessSources,
}) {
  const {
    togglePreviewLayout,
    handlePreviewLayout,
    handleShowHeader,
    handleColumnMode,
    handleSliderSettings,
    handleHeaderController,
    handleFeedTitle: setFeedTitle,
    handlePostModule,
    state: { postModule },
  } = useContext(AppContext);

  const isFirstRender = useRef(true);
  const { id } = useParams();
  const { widgetAccessConfig: hasAccessTo } = useFeatureAccess({
    contextToUse: AppContext,
  });

  const [getLayout] = useLazyQuery(GET_LAYOUT, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      const {
        columnsAndRows,
        feedTitle: feedTitleResponse,
        headers,
        sliderSettings,
        type,
      } = res?.getLayout || {};

      if (!res?.getLayout) return;

      const columnsAndRowsData = {
        col: columnsAndRows?.config?.columns,
        row: columnsAndRows?.config?.rows,
        gap: columnsAndRows?.config?.gap,
        width: columnsAndRows?.config?.width,
      };

      const { __typename, ...updateSliderSettings } = sliderSettings || {};

      const activeHeaderSection = Object?.entries(headers?.config)
        // eslint-disable-next-line no-unused-vars
        ?.filter(([_, value]) => value === true)
        ?.map(([key]) => key);

      if (res?.getLayout) {
        togglePreviewLayout(type);
        handlePreviewLayout(columnsAndRowsData);
        handleShowHeader(
          !hasAccessTo?.allowAccountInfoWithFollowButton
            ? false
            : headers?.showHeaders,
        );
        handleColumnMode(columnsAndRows?.mode);
        handleSliderSettings(updateSliderSettings);
        setFeedTitle(feedTitleResponse || '');
        handleHeaderController(activeHeaderSection);
      }
    },
    onError() { },
  });

  const [getPostConfig] = useLazyQuery(GET_POST_CONFIG, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      handlePostModule({
        ...postModule,
        callToAction: [],
      });
      if (!res?.getPostConfig) return;

      const postElementKeys = Object?.entries(res?.getPostConfig?.postElement)
        // eslint-disable-next-line no-unused-vars
        ?.filter(([_, value]) => value === true)
        ?.map(([key]) => {
          if (
            (!hasAccessTo?.allowCommentLikeCount ||
              !hasAccessTo?.allowSharingOfPost) &&
            notAllowedKeys?.some((current) => key === current)
          ) {
            return;
          }
          return key;
        });
      const popUpSettingKeys = Object?.entries(
        res?.getPostConfig?.popUPSettings,
      )
        // eslint-disable-next-line no-unused-vars
        ?.filter(([_, value]) => value === true)
        ?.map(([key]) => key);

      const callToAction = res?.getPostConfig?.callToAction?.map(
        (item, index) => {
          const { __typename, ...rest } = item;
          return { ...rest, id: index + 1 };
        },
      );

      handlePostModule({
        ...res?.getPostConfig,
        postElement: postElementKeys,
        popUPSettings: popUpSettingKeys,
        callToAction,
        style: !hasAccessTo?.allowPostHoverEffect
          ? POST_STYLE?.SIMPLE?.value
          : res?.getPostConfig?.style,
        clickAction: !hasAccessTo?.allowPostPopup
          ? POST_ACTION?.INSTAGRAM?.value
          : res?.getPostConfig?.clickAction,
      });
    },
    onError() { },
  });

  const [disconnectAccount] = useMutation(DISCONNECT_INSTAGRAM_ACCOUNT, {
    onCompleted() {
      setAccountMedia([]);
      setSourceData(null);
      getSource({
        variables: {
          where: {
            sourceType,
            widgetId: id,
          },
        },
      });
    },
  });

  useEffect(() => {
    if (isFirstRender?.current) {
      getLayout({
        variables: {
          where: {
            widgetId: id,
          },
        },
      });
      getPostConfig({
        variables: {
          where: {
            widgetId: id,
          },
        },
      });
      isFirstRender.current = false;
    }
  }, [isFirstRender]);

  const items = [
    {
      key: 'Source',
      label: (
        <div className="side-label">
          <center>
            <ApiFilled className="side-bar-icon" />
          </center>
          <center>Source</center>
        </div>
      ),
      children: (
        <Source
          accountType={accountType}
          sourceData={sourceData}
          accountMedia={accountMedia}
          setAccountMedia={setAccountMedia}
          getSource={getSource}
          headerData={headerData}
          setHeaderData={setHeaderData}
          getHeader={getHeader}
          getBusinessProfile={getBusinessProfile}
          personalHeaderLoading={personalHeaderLoading}
          getBusinessLoading={getBusinessLoading}
          disconnectAccount={disconnectAccount}
          widgetData={widgetData}
          sourceType={sourceType}
          setSourceType={setSourceType}
          setSourceData={setSourceData}
          getWidget={getWidget}
          filters={filters}
          setFilters={setFilters}
          businessSources={businessSources}
          setBusinessSources={setBusinessSources}
        />
      ),
    },
    {
      key: 'Layout',
      label: (
        <div className="side-label">
          <center>
            <LayoutFilled className="side-bar-icon" />
          </center>
          <center>Layout</center>
        </div>
      ),
      children: <WidgetLayout getLayout={getLayout} />,
    },
    {
      key: 'Post',
      label: (
        <div className="side-label">
          <center>
            <PictureFilled className="side-bar-icon" />
          </center>
          <center>Post</center>
        </div>
      ),
      children: <WidgetPost />,
    },
    {
      key: 'Settings',
      label: (
        <div className="side-label">
          <center>
            <SettingFilled className="side-bar-icon" />
          </center>
          <center>Settings</center>
        </div>
      ),
      children: (
        <Settings
          setWidget={setWidget}
          widgetData={widgetData}
          updateWidgets={updateWidgets}
          sourceData={sourceData}
          setSourceData={setSourceData}
        />
      ),
    },
  ];
  return (
    <div>
      <FeatureAccessModal />
      <Tabs
        type="card"
        tabPosition="left"
        className="main-side-bar"
        items={items}
      />
    </div>
  );
}

export default WidgetSidebar;
