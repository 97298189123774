import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import {
  GridLayoutIcon,
  MasonryLayoutIcon,
  PremiumIcon,
  SliderLayoutIcon,
} from '../../../assets/svg';
import {
  GA_EVENT_NAMES,
  GA_EVENT_PARAMETERS,
  LAYOUT_OPTIONS,
} from '../../../common/constants';
import { GaEvent } from '../../../common/utils';
import useFeatureAccess from '../../FeatureAccess/hooks/useFeatureAccess';

function ToggleLayout() {
  const {
    state: { previewStyle, openFeatureAccessModal },
    togglePreviewLayout,
    setMailSubject,
    toggleFeatureAccessModal,
  } = useContext(AppContext);
  const postPopupIsRestricted = () => {
    toggleFeatureAccessModal({
      ...openFeatureAccessModal,
      status: true,
      info:
        'Organize your posts in a stylish Masonry layout for a modern and dynamic display.',
    });
  };
  const { id } = useParams();
  const { widgetAccessConfig: hasAccessTo } = useFeatureAccess({
    contextToUse: AppContext,
  });

  const isSlider = previewStyle === LAYOUT_OPTIONS?.SLIDER?.value;
  const isGrid = previewStyle === LAYOUT_OPTIONS?.GRID?.value;
  const isMasonry = previewStyle === LAYOUT_OPTIONS?.MASONRY?.value;

  const handleChangeLayout = (value) => {
    if (value === LAYOUT_OPTIONS?.SLIDER?.value) {
      GaEvent(GA_EVENT_NAMES.SLIDER_LAYOUT, {
        [GA_EVENT_PARAMETERS.WIDGET_ID]: id,
      });
    } else if (value === LAYOUT_OPTIONS?.GRID?.value) {
      GaEvent(GA_EVENT_NAMES.GRID_LAYOUT, {
        [GA_EVENT_PARAMETERS.WIDGET_ID]: id,
      });
    } else if (value === LAYOUT_OPTIONS?.MASONRY?.value) {
      if (!hasAccessTo?.allowNewLayout) {
        setMailSubject('Show Post popup when clicking on post');
        postPopupIsRestricted();
        return;
      }
      GaEvent(GA_EVENT_NAMES.MASONRY_LAYOUT, {
        [GA_EVENT_PARAMETERS.WIDGET_ID]: id,
      });
    }
    togglePreviewLayout(value);
  };

  return (
    <div className="layout-controller">
      <div className="layout-controller-left-section">
        <div
          className=""
          onClick={() => handleChangeLayout(LAYOUT_OPTIONS?.SLIDER?.value)}
        >
          <SliderLayoutIcon
            className={`${isSlider ? 'active-layout' : ''} layout-icon`}
          />
          <span className="text-center w-full d-block">Slider</span>
        </div>
      </div>
      <div
        className="layout-controller-right-section"
        onClick={() => handleChangeLayout(LAYOUT_OPTIONS?.GRID?.value)}
      >
        <GridLayoutIcon
          className={`${isGrid ? 'active-layout' : ''} layout-icon`}
        />
        <span className="text-center w-full d-block">Grid</span>
      </div>

      <div
        className="layout-controller-right-section"
        onClick={() => handleChangeLayout(LAYOUT_OPTIONS?.MASONRY?.value)}
      >
        <MasonryLayoutIcon
          className={`${isMasonry ? 'active-layout' : ''} layout-icon`}
        />
        <div className="d-flex justify-center">
          {!hasAccessTo?.allowPostPopup && (
            <div className="mr-4">
              <PremiumIcon className="h-20 w-20" />
            </div>
          )}
          <span className="text-center w-full d-block">
            {LAYOUT_OPTIONS?.MASONRY?.label}
          </span>
        </div>
      </div>
    </div>
  );
}

export default ToggleLayout;
