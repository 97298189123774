import { InstagramOutlined } from '@ant-design/icons';
import { Button, Image } from 'antd';
import React, { useContext, useMemo } from 'react';
import i18n from '../../../i18n';

const PostHeader = ({
  profile = null,
  postData = null,
  controlPostElement,
  language = '',
  col,
  inModal = false,
  context,
}) => {
  const {
    state: {
      postModule: { postCustomStyle },
    },
  } = useContext(context);

  const { fontFamily, fontColor, padding, margin, isDarkMode } =
    postCustomStyle || {};

  const fontStyle = {
    fontFamily,
    color: isDarkMode ? 'white' : fontColor,
  };

  const completeStyle = {
    padding,
    margin,
    ...fontStyle,
  };

  const columnIsGreaterThan8 = useMemo(() => (inModal ? false : col >= 8), [
    col,
    inModal,
  ]);

  const redirectToInstagram = (permalink) => {
    // eslint-disable-next-line no-undef
    const win = window?.open(permalink, '_blank');
    win?.focus();
  };

  const translatedString = i18n?.t('Follow', { lng: language });
  return (
    <div className="post-header d-flex align-center justify-between">
      {!profile?.profilePictureUrl ? (
        <div className="user-info width-percent-70" style={completeStyle}>
          {controlPostElement('user') && (
            <div
              className={`user-full-name add-ellipsis ${
                columnIsGreaterThan8 ? 'font-s' : 'font-m'
              }`}
            >
              <span title={profile?.username || profile?.iGAccount?.username}>
                {profile?.username || profile?.iGAccount?.username}
              </span>
            </div>
          )}
          {controlPostElement('date') && (
            <div className={`${columnIsGreaterThan8 ? 'text-10' : 'font-s'}`}>
              {Intl?.DateTimeFormat('en-US', {
                month: 'long',
                day: 'numeric',
              }).format(new Date(postData?.timestamp))}
            </div>
          )}
        </div>
      ) : (
        <div
          className="d-flex align-center width-percent-70"
          style={completeStyle}
        >
          {controlPostElement('user') && (
            <div
              className={`profile-photo mr-12 ${
                columnIsGreaterThan8 ? 'pointer' : ''
              }`}
              onClick={() =>
                columnIsGreaterThan8 && redirectToInstagram(postData?.permalink)
              }
            >
              <Image src={profile?.profilePictureUrl} preview={false} />
            </div>
          )}
          <div className="user-info width-percent-70">
            {controlPostElement('user') && (
              <div
                className={`user-full-name add-ellipsis ${
                  columnIsGreaterThan8 ? 'font-s' : 'font-m'
                }`}
              >
                <span title={profile?.name || profile?.username}>
                  {profile?.name || profile?.username}
                </span>
              </div>
            )}
            {controlPostElement('date') && (
              <div className={`${columnIsGreaterThan8 ? 'text-10' : 'font-s'}`}>
                {Intl?.DateTimeFormat('en-US', {
                  month: 'long',
                  day: 'numeric',
                }).format(new Date(postData?.timestamp))}
              </div>
            )}
          </div>
        </div>
      )}

      <div
        className="d-flex align-center col-gap-4 follow-button"
        style={completeStyle}
      >
        {controlPostElement('followButton') && (
          <Button
            style={fontStyle}
            type="link"
            className="underline font-m"
            onClick={() => {
              // eslint-disable-next-line no-undef
              const win = window?.open(
                `https://www.instagram.com/${postData?.username}/`,
                '_blank',
              );
              win?.focus();
            }}
          >
            {translatedString}
          </Button>
        )}
        {controlPostElement('instagramLink') && !columnIsGreaterThan8 && (
          <div
            className="pointer"
            onClick={() => redirectToInstagram(postData?.permalink)}
          >
            <InstagramOutlined className="font-2xl" />
          </div>
        )}
      </div>
    </div>
  );
};
export default PostHeader;
